.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pointer-none > a{
  pointer-events: none!Important;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.shadow-home{
  box-shadow: 0 6px 13px rgba(0,0,0,.5) !important;
}


.ReactModal__Content.ReactModal__Content--after-open {
  height: 57vh;
  margin: 0 auto;
  top: 50%!Important;
  transform: translateY(-50%);
  padding:  50px !important;
  border: none !important;
  width: 100%;
  left: 0px !important;
  overflow: hidden !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.video::-webkit-media-controls {
  display: none !important;
}
