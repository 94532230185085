/* Personalizzazione checkbox con Tailwind */
/* This code is used to create a custom checkbox that can be styled. It is used on the edit user page. */
.custom-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.custom-checkbox span {
    position: absolute;
    top: 0;
    left: 28px;
    font-size: 1rem;
}

.custom-checkbox input[type="checkbox"]:checked + span {
    background-color: #1e88e5;
    color: #fff;
    font-weight: bold;
}

.custom-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
}

.custom-checkbox span {
    position: absolute;
    top: 0;
    left: 28px;
    font-size: 1rem;
}

.custom-checkbox input[type="checkbox"]:checked + span {
    background-color: #1e88e5;
    color: #fff;
    font-weight: bold;
}